<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kode Peternakan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formFarm.farm_code"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="kode_peternakan"
                placeholder="Masukkan kode peternakan..."
                :class="{'uk-form-danger': errors.has('kode_peternakan')}"
              >
              <div
                v-show="errors.has('kode_peternakan')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('kode_peternakan') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Peternakan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formFarm.name"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="nama_peternakan"
                placeholder="Masukkan nama peternakan..."
                :class="{'uk-form-danger': errors.has('nama_peternakan')}"
              >
              <div
                v-show="errors.has('nama_peternakan')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('nama_peternakan') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kota/Kabupaten
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="city" 
                v-model="city_choose" 
                v-validate="'required'"
                label="city" 
                name="kota" 
                track-by="city" 
                placeholder="Pilih kota/kabupaten..." 
                open-direction="bottom" 
                :options="city_locations" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('kota')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('kota') }}
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <!-- <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Pajak Penghasilan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect
                id="income_tax" 
                v-model="tax_choose" 
                v-validate="'required'"
                label="name" 
                name="income_tax" 
                track-by="name" 
                placeholder="Pilih pajak penghasilan..." 
                open-direction="bottom" 
                :options="tax_list" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('income_tax')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('income_tax') }}
              </div>
            </div>
          </div>
          <div
            v-if="show_npwp"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              NPWP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formFarm.npwp"
                v-mask="'##.###.###.#-###.###'"
                v-validate="{ required: show_npwp, min: 20 }"
                class="uk-input"
                type="text"
                name="npwp_field"
                placeholder="Masukan Nomor NPWP"
                :class="{'uk-form-danger': errors.has('npwp_field')}"
              >
              <div
                v-show="errors.has('npwp_field')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('npwp_field') }}
              </div>
            </div>
          </div> -->
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tanggal Terdaftar
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <datepicker
                v-model="dateJoin"
                v-validate="'required'"
                input-class="uk-input"
                placeholder="Pilih tanggal..."
                :format="formatDate"
                name="tanggal_terdaftar"
                :class="{'uk-form-danger': errors.has('tanggal_terdaftar')}"
              />
              <div
                v-show="errors.has('tanggal_terdaftar')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('tanggal_terdaftar') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
              style="display:flex"
            >
              <input
                v-model="formFarm.status"
                class="switcher"
                type="checkbox"
              >
              <span
                v-if="formFarm.status"
                class="uk-text-success label-unbackground"
              >{{ status.ACTIVE }}</span>
              <span
                v-else
                class="uk-text-danger label-unbackground"
              >{{ status.INACTIVE }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-text-right uk-margin-top">
        <button
          class="uk-button uk-button-danger uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="goToBack"
        >
          <img
            v-lazy="`${images}/icon/close.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Batal
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="showSaveConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { dateString } from '@/utils/formater'
import { STATUS } from '@/utils/constant'

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      isLoading: false,
      status: STATUS,
      dateJoin: new Date(),
      showProduction: process.env.VUE_APP_ENV == 'production',
      formatDate: 'DD/MM/YYYY',
      formFarm: {
        // npwp: null,
        status: 'true',
        city_id: ''
      },
      city_choose: '',
      // tax_choose: '',
      city_locations: []
      // tax_list: [
      //   {
      //     name: 'Pribadi (PPh 21) dengan NPWP',
      //     show: true,
      //     value: "2.5"
      //   },
      //   {
      //     name: 'Pribadi (PPh 21) tanpa NPWP',
      //     show: false,
      //     value: '3'
      //   },
      //   {
      //     name: 'Badan (PPh 23) dengan NPWP',
      //     show: true,
      //     value: '2'
      //   },
      //   {
      //     name: 'Badan (PPh 23) tanpa NPWP',
      //     show: false,
      //     value: '4'
      //   }
      // ],
      // show_npwp: false
    }
  },
  computed: {
    ...mapGetters({
      cities: 'city/cities'
    })
  },
  watch: {
    tax_choose() {
      this.show_npwp = this.tax_choose ? this.tax_choose.show : false
    },
    'formFarm.join_date'() {
      this.formFarm.join_date = this.dateFormatFilter(this.formFarm.join_date)
    }
  },
  async mounted() {
    await this.getCity({is_all: true})
    this.city_locations = this.cities ? this.cities : []
  },
  methods: {
    ...mapActions({
      getCity: 'city/getCity'
    }),
    ...mapMutations({
      setModalAdd: 'farm/SET_MODAL_ADD'
    }),
    dateFormatFilter(date) {
      return dateString(date)
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.formFarm.status = this.formFarm ? String(this.formFarm.status) : ''
          this.formFarm.city_id = this.city_choose ? this.city_choose.id : ''
          this.formFarm.join_date = this.dateFormatFilter(this.dateJoin)
          this.setModalAdd(this.formFarm)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    }
  }
}
</script>
